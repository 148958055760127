import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {observer} from 'mobx-react';
import {observable, reaction} from 'mobx';
import NotificationArea from 'component/NotificationArea';
import NotificationTab from 'component/NotificationTab';
import Login from 'screen/Login/Login';
import AppHeader from './AppHeader';
import {withRouter} from 'react-router-dom';
import Router from './Router';
import StartupError from './StartupError';
import RuntimeError from './RuntimeError';
import {AppContainer, Body} from 're-cy-cle';
import Raven from 'raven-js';
import {theme} from 'styles';
import {Helmet} from 'react-helmet';
import {TAB_TITLE_PREFIX} from 'helpers';
import AppSidebar from './AppSidebar';
import styled from 'styled-components';
import Scrollbars from 'react-custom-scrollbars';
import {NotificationStore} from 'store/Notification';
import TwoFactorAuth from "./TwoFactorAuth";
import {getCurrentUser} from "../helpers/currentUser";
import ChatTab from "./Chat/ChatTab";
import {MessageStore} from "../store/Message";
import { decodeUrl } from 'spider/helpers/bindUrlParams';

const pathsThatSkipAuthentication = [
    /^\/login\/forgot$/,
    /^\/user\/\d+\/reset-password\/[^/]+$/,
    /^\/register/,
    /^\/subscription/
];

const FlexContainer = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: ${({direction = 'row'}) => direction};
    overflow: hidden;
`;

const SidebarContent = styled.div`
    ${({open}) => open ? `
        box-shadow: 0 0 5px 3px rgba(0, 0, 0, 0.1);
        min-width: 400px !important;
    ` : `
        min-width: 0;
        box-shadow: 0 0 5px 3px transparent;
    `}

    //border-left: 1px solid rgba(34, 36, 38, 0.15);
    overflow: visible;
    transition: margin-left 300ms ease, box-shadow 300ms ease, min-width 300ms ease;
    background-color: #FFF;
    z-index: 50;
    > div > div {
        display: flex;
        flex-direction: column;
    }

    position: relative;
`;


@withRouter
@observer
export default class App extends Component {
    static propTypes = {
        store: PropTypes.object.isRequired,
        twoFactorAuthModalIsShown: PropTypes.bool,
        location: PropTypes.object,
    };

    @observable tab = JSON.parse(localStorage.currentTab || 'null');

    @observable notificationStore = new NotificationStore({
        relations: ['workOrder'],
        params: {
            '.seen_at:isnull': true,
            order_by: '-created_at'
        }
    });

    @observable messageStore = new MessageStore({
        relations: ['patient'],
        params: {
            order_by: '-sent_at'
        },
        // Show newest messages first
        comparator: (a, b) => b.sentAt - a.sentAt
    })

    @observable selectedPatientForChat = null;
    @observable chatMessageTemplate;


    componentDidMount() {
        const user = getCurrentUser();
        reaction(() => {
            return this.props.store.isAuthenticated && this.props.store.api.socket && user && user.id
        }, () => {
            if (!user.isNew) {
                this.notificationStore.fetch();
                this.notificationStore.subscribe(user);

                const messagePermission = user.permissions.find(permission => permission.includes('view_message'));
                if (messagePermission) {
                    const customer = this.props.store.currentUser?.pharmacist.customer
                    if (customer) {
                        this.messageStore.initializeMessageOverviewDataForCustomer(customer)
                    }
                }
            }
        }, {fireImmediately: true});

        // Attach function to window, so it can be accessed throughout the whole app
        window.setPatientForChat = this.setSelectedPatient;

        if (decodeUrl('showChat') === 'true') {
            this.tab = 'chat';
        }
    }

    setSelectedPatient = (patient, template) => {
        this.tab = 'chat';
        this.selectedPatientForChat = patient;
        this.chatMessageTemplate = template;
        // this.forceUpdate()
    }

    componentWillUnmount() {
        this.messageStore.unsubscribeFromMessageOverviewData();
    }


    onTabSelect = (tab) => {
        if (tab === this.tab && !this.callVisible) {
            tab = null;
        }
        this.tab = tab;
        localStorage.currentTab = JSON.stringify(tab);
    }

    getTabContent() {
        switch (this.tab) {
            case 'notifications':
                return <NotificationTab notificationStore={this.notificationStore}/>;

            case 'chat':
                return window.viewStore.currentUser.pharmacist?.customer.chatEnabled ? 
                    <ChatTab closeChat={() => this.onTabSelect(null)} 
                            selectedPatient={this.selectedPatientForChat} 
                            template={this.chatMessageTemplate} 
                            messageStore={this.messageStore}/>:null;

            default:
                return null;
        }
    }

    static childContextTypes = {
        viewStore: PropTypes.object,
    };

    getChildContext() {
        return {
            viewStore: this.props.store,
        };
    }

    componentDidCatch(err) {
        this.hasCrashed = true;
        if (process.env.CY_FRONTEND_SENTRY_DSN) {
            Raven.captureException(err);
            Raven.showReportDialog();
        }
    }

    @observable hasCrashed = false;

    render() {
        const {store, location} = this.props;

        const tab = this.getTabContent();

        if (this.hasCrashed) {
            return <RuntimeError/>;
        }

        let content = null;

        const useAuthenticatedRouter = store.isAuthenticated ||
            pathsThatSkipAuthentication.some(regex =>
                regex.test(location.pathname)
            )

        if (
            useAuthenticatedRouter
        ) {
            content = <Router store={store}/>;
        } else if (store.bootstrapCode === 200) {
            // Only show two-factor auth if login modal not shown
            if (store.twoFactorAuthModalIsShown){
                 content = null;
            } else if (store.auth.status === 'waiting' || store.auth.status === 'expired') {
                content = <TwoFactorAuth viewStore={store}/>;
            } else {
                content = <Login viewStore={store} labelColor="white" submitButtonColor="#448896"/>;
            }

        } else if (store.bootstrapCode !== null) {
            // The not null check is important, since we don't want to flash a startup error while the XHR request is running.
            return <StartupError code={store.bootstrapCode}/>;
        }


        return (
            <React.Fragment>
                <Helmet>
                    <title>{TAB_TITLE_PREFIX}</title>
                </Helmet>
                <FlexContainer>
                    <AppContainer data-theme={JSON.stringify(theme)}>
                        <AppHeader store={store} location={location}/>
                        <Body id="app-body">{content}</Body>

                        {store.currentModal ? (
                            <store.currentModal.render
                                viewStore={store}
                                {...store.currentModal}
                            />
                        ) : null}
                        <NotificationArea store={store}/>
                    </AppContainer>

                    <SidebarContent open={tab !== null && store.isAuthenticated}>
                        <Scrollbars autoHide>
                            {tab}
                        </Scrollbars>
                    </SidebarContent>

                    {(store.isAuthenticated) && (
                        <AppSidebar
                            selected={this.tab}
                            onSelect={this.onTabSelect}
                            store={store}
                            notificationStore={this.notificationStore}
                            messageStore={this.messageStore}
                        />
                    )}
                </FlexContainer>
            </React.Fragment>
        );
    }
}
