import React, {Component} from 'react';
import {observer} from 'mobx-react';
import {observable, reaction} from 'mobx';
import {Button, MenuRow, NavItem, NavMenu, TopMenu} from 're-cy-cle';
import {Header, Icon, Modal} from 'semantic-ui-react';
import {Route, withRouter} from 'react-router-dom';
import {SmallAvatar} from 'component/UserAvatar';
import {BUILD_INFO} from 'helpers';
import {t} from '../i18n';
import ViewStore from "../spider/store/View";
import {
    CMS_MENU_PERMISSIONS,
    MENU_ACCESS_LOG,
    MENU_ASSET,
    MENU_ASSIGNMENT,
    MENU_CMS_CATEGORIES,
    MENU_CMS_GENERIC_MOMENTS,
    MENU_CMS_INFORMATION_PAGE,
    MENU_CMS_LIBRARY,
    MENU_CMS_TREATMENT_GROUPS,
    MENU_CMS_TREATMENTS,
    MENU_PATIENT,
    MENU_PERMISSIONS,
    MENU_REPORT,
    MENU_TREATMENT
} from "../NEN7510/Menu";
import {CustomerDashboardStore} from "store/Customer/CustomerDashboard";
import {SUPERUSER} from "../NEN7510/Groups";

// All the different top-level navigation items

const CMS_ITEMS = {
    [MENU_CMS_TREATMENTS]: () => (
        <NavItem
            title={<span data-test-nav-treatment-overview>{t('nav.treatment.overview')}</span>}
            to="/treatment/overview"
            activePath="/treatment/overview"
            checkActive={(match, location) => {
                const treatmentRegex = /treatment\/overview|treatment\/\d+/
                return treatmentRegex.test(location.pathname)
            }}
        />
    ),
    [MENU_CMS_TREATMENT_GROUPS]: () => (
        <NavItem
            title={<span data-test-nav-treatment-treatment-group>{t('nav.treatment.treatmentGroup')}</span>}
            to="/treatment/treatmentgroup/list/"
            activePath="/treatment/treatmentgroup/"
        />
    ),
    [MENU_CMS_GENERIC_MOMENTS]: () => (
        <NavItem
            title={<span data-test-nav-treatment-generic-moment>{t('nav.treatment.genericMoment')}</span>}
            to="/treatment/genericmoment/overview/"
            activePath="/treatment/genericmoment/"
        />
    ),
    [MENU_CMS_CATEGORIES]: () => (
        <NavItem
            title={<span data-test-nav-treatment-category>{t('nav.treatment.category')}</span>}
            to="/treatment/category/overview"
            activePath="/treatment/category"
        />
    ),
    [MENU_CMS_LIBRARY]: () => (
        <NavItem
            title={<span data-test-nav-treatment-library>{t('nav.treatment.library')}</span>}
            to="/treatment/library/questionnaire/overview"
            activePath="/treatment/library/"
        />
    ),
    [MENU_CMS_INFORMATION_PAGE]: () => (
        <NavItem
            title={<span data-test-nav-treatment-page>{t('nav.treatment.page')}</span>}
            to="/treatment/page/overview"
            activePath="/treatment/page/"
        />
    )
}


interface AppHeaderProps {
    store: ViewStore;
    location: any;
}

// @ts-ignore
@withRouter
@observer
export default class AppHeader extends Component<AppHeaderProps> {

    @observable TOP_MENU_ITEMS = {
        [MENU_ASSIGNMENT]: () => (
            <NavItem
                title={<span data-test-nav-assignment>{t('nav.main.workOrder')}</span>}
                to="/workorder/template/overview"
                activePath="/workorder/"
            />
        ),
        [MENU_ASSET]: () => (
            <NavItem
                title={<span data-test-nav-asset>{t('nav.main.assets')}</span>}
                to="/assets/user/overview"
                activePath="/assets/"
            />
        ),
        [MENU_TREATMENT]: () => (
            <NavItem
                title={<span data-test-nav-treatment>{t('nav.main.treatment')}</span>}
                to="/treatment/overview"
                activePath="/treatment/"
            />
        ),
        [MENU_PATIENT]: () => (
            <NavItem
                title={<span data-test-nav-patient>{t('nav.main.patient')}</span>}
                to="/patient/patient/overview"
                activePath="/patient/"
            />
        ),
        [MENU_REPORT]: () => (
            <NavItem
                title={<span data-test-nav-report>{t('nav.main.report')}</span>}
                to="/report/"
                activePath="/report/"
            />
        ),
        [MENU_ACCESS_LOG]: () => (
            <NavItem
                title={<span data-test-nav-access-log>Access Logs</span>}
                to="/audit/access-log/overview"
                activePath="/audit/access-log/"
            />
        )
    }
    @observable debug = false;
    @observable showLogoutModal = false;
    // @ts-ignore
    @observable customerDashboardStore = new CustomerDashboardStore({relations: ['dashboard']});

    reactions = [];

    componentDidMount() {
        // @ts-ignore
        this.reactions.push(reaction(
                () => this.customerDashboardStore.length,
                () => {
                    this.forceUpdate();
                },
                {fireImmediately: true}
            )
        )
    }

    componentWillUnmount() {
        this.reactions.forEach(reaction => {
            // @ts-ignore
            reaction();
        });
        this.reactions = [];
    }

    toggleDebug = () => {
        this.debug = !this.debug;

        if (this.debug) {
            localStorage.setItem('debug', 'true');
        } else {
            localStorage.removeItem('debug');
        }
    }

    constructor(...args) {
        // @ts-ignore
        super(...args);
        this.reactions = [];
        this.debug = !!localStorage.getItem('debug');
    }

    hasPermission = perms => {
        return this.props.store.currentUser.hasPermission(perms);
    };

    renderAssets = () => {
        return (
            <NavMenu>
                <NavItem
                    title={t('nav.assets.users')}
                    to="/assets/user/overview"
                    activePath="/assets/user/"
                />
                <NavItem
                    title={t('nav.assets.customers')}
                    to="/assets/customer/overview"
                    activePath="/assets/customer/"
                />
                <NavItem
                    title={t('nav.assets.customerGroup')}
                    to="/assets/customergroup/overview"
                    activePath="/assets/customergroup"
                />
                <NavItem
                    title={t('nav.pharmacist.overview')}
                    to="/assets/pharmacist/pharmacist/overview"
                    activePath="/assets/pharmacist/pharmacist"
                />
                <NavItem
                    title={t('nav.assets.treatmentCustomerLink')}
                    to="/assets/treatmentcustomerlink/overview"
                    activePath="/assets/treatmentcustomerlink"
                />
            </NavMenu>
        );
    };


    renderWorkorder = () => {
        const {location} = this.props
        const regexPath = new RegExp('/workorder/[0-9]+/preview')

        return (
            <NavMenu>
                <NavItem
                    title={t('nav.main.workOrder')}
                    to="/workorder/template/overview"
                    activePath="/workorder/template"
                />
                <NavItem
                    title={t('nav.main.checkContent')}
                    to="/workorder/check-content/overview"
                    activePath="/workorder/check-content"
                />
                {
                    regexPath.test(location.pathname) &&
                    <NavItem
                        title={t('nav.main.preview')}
                        to={location.pathname}
                        activePath={location.pathname}
                    />
                }
            </NavMenu>
        );
    };

    renderPatient = () => {
        const path = this.props.location.pathname.split('/');
        const shouldRenderPatientDetails = path.length >= 5 && path[1] === 'patient' && path[2] === 'patient' && path[4] === 'edit';

        return (<NavMenu>
            <NavItem
                title={t('nav.patient.overview')}
                to="/patient/patient/overview"
                activePath="/patient/patient/overview"
            />

            {shouldRenderPatientDetails && <NavItem
                title={t('nav.patient.data')}
                to={this.props.location.pathname}
                activePath="/patient/patient"
                checkActive={() => true}
            />}


        </NavMenu>);
    };


    renderTreatment = () => {
        const user = this.props.store.currentUser;
        let items = user.groups.map(group => CMS_MENU_PERMISSIONS[group.name]).flat();
        // SuperAdmins do not count as group and need their stuff added manually
        if (user.isSuperuser) {
            items.push(...CMS_MENU_PERMISSIONS[SUPERUSER]);
        }
        // @ts-ignore
        // Remove duplicates
        items = [...new Set(items)];
        return (
            <NavMenu data-test-bottom-menu-row>
                {items.map(item => {
                    // @ts-ignore
                    return CMS_ITEMS[item]();
                })}
            </NavMenu>
        )
    };


    renderAudit = () => {
        return (
            <NavMenu>
                <NavItem
                    title={t('nav.audit.accessLog')}
                    to="/audit/access-log/overview"
                    activePath="/audit/access-log/"
                />
            </NavMenu>
        );
    }


    renderAccountMenu = () => {
        const {store} = this.props;
        // @ts-ignore
        const {version, branch} = BUILD_INFO;

        return (
            <NavItem
                title={
                    <span data-test-current-user>
                        <SmallAvatar user={store.currentUser}/>
                        {" "}
                        {store.currentUser.fullName} (
                        {branch && branch !== 'production' && branch + ' '}
                        {version}
                        )
                    </span>
                }
                to="/account/details"
                activePath="/account/"
            />
        );
    };


    renderAccount = () => {
        const {store} = this.props;
        return (
            <NavMenu>
                <NavItem
                    title={t('nav.account.account')}
                    to="/account/details"
                />
                {store.currentUser.canViewChangeLog && (
                    <React.Fragment>
                        <NavItem title={t('nav.account.changelog')} to="/account/changelog"/>
                    </React.Fragment>
                )}

            </NavMenu>
        );
    };

    renderReports = () => {
        const {store} = this.props;

        // If no customer then do not render reports
        const customer = store.currentUser?.pharmacist?.customer;
        if (!customer || customer.isNew) {
            return false;
        }

        return this.customerDashboardStore.length > 0 && (
            <NavMenu>
                {this.customerDashboardStore?.map((customerDashboard) => <React.Fragment>
                        <NavItem title={`${customerDashboard.dashboard.title}`}
                                 to={`/report/${customerDashboard.dashboard.dashboardId}/`}/>
                    </React.Fragment>
                )}

            </NavMenu>
        );
    };

    maybeOverrideReportUrl() {
        const {store} = this.props
        const customer = store.currentUser?.pharmacist?.customer
        if (!customer || customer.isNew) {
            return false
        }

        // If customerDashboardStore not initialized, initialize
        if (!this.customerDashboardStore.params['.customer']) {
            // @ts-ignore
            this.customerDashboardStore.params['.customer'] = this.props.store.currentUser?.pharmacist?.customer?.id;
            this.customerDashboardStore.fetch().then(() => {
                if (this.customerDashboardStore.length <= 0) {
                    return false
                }
                const dashboardId = this.customerDashboardStore.at(0).dashboard.dashboardId
                // Replace the report url
                this.TOP_MENU_ITEMS.report = () => <NavItem
                    title={<span data-test-nav-report>{t('nav.main.report')}</span>}
                    to={`/report/${dashboardId ? dashboardId + "/" : ''}`}
                    activePath="/report/"
                />
            })
        }


    }

    renderMenuItems() {
        const user = this.props.store.currentUser;
        let items = user.groups.map(group => MENU_PERMISSIONS[group.name]).flat();
        // SuperAdmins do not count as group and need their stuff added manually
        if (user.isSuperuser) {
            items.push(...MENU_PERMISSIONS[SUPERUSER]);
        }
        // @ts-ignore
        // Remove duplicates
        items = [...new Set(items)];
        this.maybeOverrideReportUrl();

        return items.map(item => {
            // @ts-ignore
            return (this.TOP_MENU_ITEMS[item]());
        });
    }

    render() {
        const {currentUser} = this.props.store;

        if (!this.props.store.isAuthenticated) {
            return (<></>);
        }

        if (!this.props.store.isAuthenticated || (
            currentUser.groupNames === undefined &&
            !currentUser.isSuperuser
        )) {
            return (
                <TopMenu>
                    <MenuRow>
                    </MenuRow>
                    <MenuRow/>
                </TopMenu>
            );
        }

        const logoutModal = (
            <Modal closeIcon open={this.showLogoutModal} onClose={() => this.showLogoutModal = false} basic
                   size='small'>
                <Header icon='archive' content={t('user.account.logoutButton')}/>
                <Modal.Content>
                    <p>
                        {t('user.account.logout.confirm')}
                    </p>
                </Modal.Content>
                <Modal.Actions>
                    <Button basic color='red' inverted onClick={() => this.showLogoutModal = false}>
                        <Icon name='remove'/> {t('form.no')}
                    </Button>
                    <Button color='green' inverted onClick={() => {
                        this.props.store.performLogout().then(() => {
                            this.showLogoutModal = false;
                        });
                    }}>
                        <Icon name='checkmark'/> {t('form.yes')}
                    </Button>
                </Modal.Actions>
            </Modal>
        );

        let menu = (
            <TopMenu>
                <MenuRow>
                    <NavMenu>
                        {this.renderMenuItems()}
                    </NavMenu>
                    {this.renderAccountMenu()}
                </MenuRow>
                <MenuRow>
                    <Route path="/account" render={this.renderAccount}/>
                    <Route path="/assets/" render={this.renderAssets}/>
                    <Route path="/workorder/" render={this.renderWorkorder}/>
                    <Route path="/treatment/" render={this.renderTreatment}/>
                    <Route path="/patient/" render={this.renderPatient}/>
                    <Route path="/report/" render={this.renderReports}/>
                </MenuRow>
            </TopMenu>
        );

        return (
            <React.Fragment>
                {menu}
                {logoutModal}
            </React.Fragment>
        );
    }
}
