import { Model, Store } from 'store/Base';
import { observable } from 'mobx';
import { PatientStore } from '../Patient';
import { CustomerCustomerGroupStore } from "../TreatmentCustomerLink/CustomerCustomerGroup";
import { Treatment } from "../Treatment";
import { User } from "../User";
import { CustomerDashboardStore } from "./CustomerDashboard";


export const TYPE_INSTANTLY = 'instantly';
export const TYPE_ONCE_AN_HOUR = 'once_an_hour';
export const TYPE_ONCE_DAY = 'once_day';
export const TYPE_TWICE_DAY = 'twice_day';
export const TYPE_NEVER = 'never';
export const NOTIFICATION_FREQUENCY = [TYPE_INSTANTLY, TYPE_ONCE_AN_HOUR, TYPE_ONCE_DAY, TYPE_TWICE_DAY, TYPE_NEVER];

export class Customer extends Model {

    static backendResourceName = 'customer';

    static fileFields = ['logo'];

    @observable id = null;
    @observable name = null;
    @observable identifier = null;

    @observable phoneNumber = null;
    @observable email = null;
    @observable website = null;

    @observable street = null;
    @observable houseNumber = null;
    @observable zipCode = null;
    @observable city = null;

    @observable logo = null;

    @observable notifyPatients = true;
    @observable chatEnabled = false;
    @observable signalsEnabled = false;
    @observable multilanguageEnabled = false;
    @observable treatmentConfirmationEnabled = true;
    @observable indicationConfirmationEnabled = true;
    @observable scheduleConfirmationEnabled = true;
    @observable notificationFrequency = '';
    @observable notificationEmail = null;
    @observable emailPatientNotification = true;
    @observable patientImportConfig = null;

    @observable appMainColor = '#5ED1BC';
    @observable appMenuColor = '#FFFFFF';
    @observable appMenuActiveIconColor = '#5ED1BC';
    @observable appMenuInactiveIconColor = '#000000';
    @observable emailAccentColor = '#008C99';
    @observable emailButtonColor = '#008C99';

    @observable powerbiGroup = '';

    @observable customerLanguages= [];

    @observable apiPublicKey = null;
    @observable customizedIntroductionEmailText = null;

    @observable deleted = false;

    relations() {
        return {
            patients: PatientStore,
            customerCustomerGroups: CustomerCustomerGroupStore,
            path: Treatment,
            apiUser: User,
            customerDashboards: CustomerDashboardStore,
        };
    }
}

export class CustomerStore extends Store {
    Model = Customer;
    static backendResourceName = 'customer';
}
